export const ENV_VARIABLES = Object.freeze({
  env: {
    Region: 'us-east-1',
    Stage: 'alpha',
    CloudFrontProtocol: 'https',
    CloudFrontDomain: 'd3mii6aqajpvr1.cloudfront.net',
    CognitoUserPoolId: 'us-east-1_4OO8XuiAH',
    CognitoIdentityPoolId: 'us-east-1:a9b1c383-963a-468d-96e4-d461e79b579c',
    AuthenticationType: 'AMAZON_COGNITO_USER_POOLS'
  }
});

export const getAmplifyAuthConfig = () => {
  return {
    Auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_4OO8XuiAH',
      userPoolWebClientId: '6rmn1jjl5c0t86e2nltb0raqfq',
      identityPoolId: 'us-east-1:a9b1c383-963a-468d-96e4-d461e79b579c',
      awsAccountId: '571222351571',
      mandatorySignIn: true,
      authenticationFlowType: 'USER_SRP_AUTH',
      oauth: {
        domain: 'fast-input-tool-alpha.auth.us-east-1.amazoncognito.com',
        scope: ['openid'],
        redirectSignIn: 'https://alpha.fast-input-tool.device.finance.amazon.dev',
        redirectSignOut: 'https://alpha.fast-input-tool.device.finance.amazon.dev',
        responseType: 'code'
      }
    }
  };
};
