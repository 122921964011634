import KatalLogger, { Level } from '@amzn/katal-logger';
import { UserAuthContext } from 'src/models/ApplicationModels';

export let logger: KatalLogger;

export const configureTheLogger = (userAlias: string, userAuthData: UserAuthContext) => {
  const katalLoggerConfig = {
    url: 'https://alpha.logger.fast-input-tool.device.finance.amazon.dev/v1/log',
    logThreshold: Level.INFO,
    maxLogLineSize: 10000,
    logToConsole: true,
    context: {
      app: 'fast-input-tool-website',
      env: 'alpha',
      userAlias: userAlias,
      userAuthData: userAuthData
    }
  };
  logger = new KatalLogger(katalLoggerConfig);
};
